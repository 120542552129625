import React from "react";
import { AiFillAppstore, AiOutlineFieldTime, AiOutlineDollar, AiFillSecurityScan } from "react-icons/ai";

import { FaHandshake, FaBolt } from "react-icons/fa";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title des="Why ClientJO" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Round-the-Clock Assistance"
          des="We provide continuous support to ensure your business operates smoothly at any hour."
          icon={<AiOutlineFieldTime />}
        />
        <Card
          title="Quality Focus"
          des="We are dedicated to delivering top-quality services that meet your expectations every time."
          icon={<AiFillAppstore />}
        />
        <Card
          title="Cost Reduction"
          des="We help businesses save money by streamlining processes, ensuring operations run efficiently and effectively with minimal waste."
          icon={<AiOutlineDollar />}
        />
        <Card
          title="Data Security"
          des="We prioritize protecting your data using advanced security methods to ensure it remains safe and confidential at all times."
          icon={<AiFillSecurityScan />}
        />
        <Card
          title="Reliability"
          des="You can count on us to provide consistent, reliable services that meet your needs and help your business run smoothly."
          icon={<FaHandshake />}
        />
        <Card
          title="Speed & Efficiency"
          des="We focus on getting tasks done quickly and efficiently, without compromising the quality or accuracy of the results."
          icon={<FaBolt />}
        />
      </div>
    </section>
  );
};

export default Features;
