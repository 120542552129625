import React from "react";
import Title from "../layouts/Title";
import { developerImg, salesImg, customerServiceImg, mediaImg, designImg, photographyImg, recruitmentImg, advertismentImg } from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="solutions"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title des="Solutions" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Customer Service"
          des="Reliable and cost-effective customer service solutions, ensuring efficient and timely support across phone, email, live chat, and social media. Let us help you enhance customer satisfaction while you focus on growing your business."
          src={customerServiceImg}
        />
        <ProjectsCard
          title="Sales & Marketing"
          des="Comprehensive marketing and sales outsourcing solutions designed to increase brand awareness, generate leads, and drive sales. Let us help you grow your business with tailored marketing strategies and expert sales support that deliver results."
          src={salesImg}
        />  
        <ProjectsCard
          title="Web & Application Development"
          des="High-quality web and application development services, specializing in modern, responsive, and scalable solutions tailored to your business needs. From user-friendly websites to custom web and mobile applications, we help businesses thrive in the digital world."
          src={developerImg}
        />
        <ProjectsCard
          title="Social Media"
          des="Complete advertising solutions to help your business reach the right audience and achieve real results across platforms like Google, Facebook, and traditional media. Let our team handle your advertising needs, so you can focus on growing your business."
          src={mediaImg}
        />
        <ProjectsCard
          title="Advertisment"
          des="Complete advertising solutions to help your business reach the right audience and achieve real results across social media, search engines, and traditional media. Let us handle your advertising needs while you focus on growing your business."
          src={advertismentImg}
        />
        <ProjectsCard
          title="Graphic Design"
          des="Expert graphic design services to create visually impactful designs, including logos, marketing materials, and digital graphics. Let us elevate your brand with creative solutions that resonate with your audience and drive business growth."
          src={designImg}
        />
        <ProjectsCard
          title="Photography"
          des="Professional photography services, from product photography to corporate events, helping your business capture its best moments. Let us deliver high-quality visuals that enhance your brand and leave a lasting impression."
          src={photographyImg}
        />
        <ProjectsCard
          title="Recruitment"
          des="Comprehensive recruitment solutions to help businesses efficiently find the right talent. Let us handle your hiring process so you can focus on growing your business."
          src={recruitmentImg}
        />
      </div>
    </section>
  );
};

export default Projects;
