import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: [
      "Customer Service (Calls & Chat)",
      "Social Media",
      "Web & Application Development",
      "Marketing & Sales",
      "Advertisement",
      "Photography",
      "Graphic Design",
      "Recruitment",
    ],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h1 className="text-3xl font-bold text-white pb-8">Coming Soon!</h1>
        <h1 className="text-6xl font-bold text-white">
          Welcome To{" "}
          <span className="text-designColor capitalize">ClientJO</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#047474"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        ClientJo is a leading provider of outsourcing services, offering expertise in customer service, marketing, social media management, web and application development, sales, advertising, photography, graphic design, and recruitment. Our solutions are designed to enhance business efficiency and support sustainable growth.
        </p>
      </div>
    </div>
  );
};

export default LeftBanner;
